<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4"> 
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">  
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Member </h3> 
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-between">
                  <h2 class="card-title ">
                    <button class="button-1 w-100 btn btn-md btn-primary" type="submit"><a href="/addmember" style="color:#FFFFFF !important">Add +</a></button>
                  </h2>
                  <form class="d-flex">
                    <input class="form-control" type="search" placeholder="Search" aria-label="Search">
                    <svg width="16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="search-box svg-inline--fa fa-search fa-w-16 fa-fw">
                      <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" class=""></path>
                    </svg>
                  </form>
                </div>                         
              </div>
              <!-- Tabla-->
              <table class="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </th>
                    <th scope="col"><span class="text-blue">Name</span></th>
                    <th scope="col"><span class="text-blue">Type</span></th>
                    <th scope="col"><span class="text-blue">Email</span></th>
                    <th scope="col"><span class="text-blue">Actions</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row"><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                    <td>
                      <svg class="bd-placeholder-img flex-shrink-0 me-2 rounded-circle" width="24" height="24" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#C4C4C4"></rect><text x="50%" y="50%" fill="#C4C4C4" dy=".3em">24x24</text></svg> 
                      Mark Otto
                    </td>
                    <td>Member</td>
                    <td>motto@gmail.com</td>
                    <td>
                      <div class="d-flex justify-content-around">
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Edit" aria-label="View Screen"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Delete" aria-label="Add note"><i class="fa fa-trash" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue"  title="" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                        <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                    <td><svg class="bd-placeholder-img flex-shrink-0 me-2 rounded-circle" width="24" height="24" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#C4C4C4"></rect><text x="50%" y="50%" fill="#C4C4C4" dy=".3em">24x24</text></svg> Mark Otto</td>
                    <td>Member</td>
                    <td>motto@gmail.com</td>
                    <td>
                      <div class="d-flex justify-content-around">
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Edit" aria-label="View Screen"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Delete" aria-label="Add note"><i class="fa fa-trash" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue"  title="" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                        <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                    <td>
                      <svg class="bd-placeholder-img flex-shrink-0 me-2 rounded-circle" width="24" height="24" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#C4C4C4"></rect><text x="50%" y="50%" fill="#C4C4C4" dy=".3em">24x24</text></svg> 
                      Mark Otto
                    </td>
                    <td>Member</td>
                    <td>motto@gmail.com</td>
                    <td>
                      <div class="d-flex justify-content-around">
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Edit" aria-label="View Screen"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Delete" aria-label="Add note"><i class="fa fa-trash" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue"  title="" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                        <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuButton3">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></th>
                    <td>
                      <svg class="bd-placeholder-img flex-shrink-0 me-2 rounded-circle" width="24" height="24" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#C4C4C4"></rect><text x="50%" y="50%" fill="#C4C4C4" dy=".3em">24x24</text></svg> 
                      Mark Otto
                    </td>
                    <td>Member</td>
                    <td>motto@gmail.com</td>
                    <td>
                      <div class="d-flex justify-content-around">
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Edit" aria-label="View Screen"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Delete" aria-label="Add note"><i class="fa fa-trash" aria-hidden="true"></i></a>
                        <a href="#" class="text-blue"  title="" id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                        <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuButton4">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- end tabla -->
              <div class="d-flex justify-content-end m-1">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item"><a class="text-blue page-link" href="#">Previous</a></li>
                    <li class="page-item"><a class="text-blue page-link" href="#">1</a></li>
                    <li class="page-item"><a class="text-blue page-link" href="#">2</a></li>
                    <li class="page-item"><a class="text-blue page-link" href="#">3</a></li>
                    <li class="page-item"><a class="text-blue page-link" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>                        
            </div>                    
          </div>
        </div>                
      </div>
    </div> 
  </main>
</template>

<script>
  export default
  {
    name: 'ContentMember'
  }
</script>

<style></style>
